import styled from "@emotion/styled";
import { scale } from "../utils/typography";
import { mq } from "../utils/helper";

export const colors = {
  white: "#fff",
  black: "#000",
  light: "#fff",
  green: "#00baa3",
  red: "#00baa3",
  dark: "#444444",
  yellow: "#efc94c",
  bg: "#f6f6f6",
};

export const Input = styled.input({
  backgroundColor: colors.bg,
  padding: "0.5rem",
  color: colors.dark,
  border: `3px solid #f1f1f1`,
  height: "2.2rem",
  display: "block",
  [mq[1]]: {
    width: "100%",
  },
});

export const Button = styled.button({
  fontSize: "1.1rem",
  fontWeight: 600,
  backgroundColor: colors.green,
  color: colors.light,
  border: `3px solid ${colors.light}`,
  borderRadius: "15px",
  marginTop: "0.3rem",
  padding: "0.4rem 0.7rem",
  alignSelf: "flex-end",
  ":hover, :active": {
    cursor: "pointer",
  },
  [mq[1]]: {
    width: "100%",
    marginTop: "1rem",
  },
});

export const H5 = styled.h5({
  fontWeight: "bold",
  letterSpacing: "0.88px",
  color: colors.red,
  fontSize: "95%",
  margin: 0,
  marginBottom: "0.8rem",
});

export const FeedTitle = styled.h3({
  color: colors.dark,
  textDecoration: "none",
  transition: "0.25s",
  lineHeight: 1.5,
  ":hover, :active": {
    backgroundColor: colors.light,
    color: colors.green,
  },
});
export const FeedLink = styled.a({
  color: colors.dark,
  textDecoration: "none",
  transition: "0.25s",
  lineHeight: 1.5,
  ":hover, :active": {
    backgroundColor: colors.light,
    color: colors.green,
  },
});

export const Creatorlink = styled.div({
  color: colors.black,
  textDecoration: "none",
  transition: "0.25s",
  ":hover, :active": {
    textDecoration: "underline",
  },
});

export const A = styled.a({
  ...scale(0.2),
  color: colors.dark,
  textDecoration: "none",
  fontWeight: 600,
  ":hover, :active": { color: colors.light },
  ":visited, :link": {
    textDecoration: "none",
  },
});

export const GreenLink = styled.a({
  textDecoration: "none",
  color: colors.green,
  fontWeight: 600,
  ":hover, :active": {},
  ":visited, :link": {
    textDecoration: "none",
  },
});

export const Cardbox = styled.div({
  display: "flex",
  boxShadow: "1px 2px 15px #e5e5e5",
  maxWidth: "800px",
  minHeight: "320px",
  Height: "320px",
  maxHeight: "320px",
  margin: "1.5rem auto",
  lineHeight: 1.5,
  backgroundColor: colors.light,
  [mq[1]]: {
    display: "block",
    height: "auto",
    maxHeight: "none",
  },
  [mq[3]]: {
    maxHeight: "none",
  },
  ":hover": {
    cursor: "pointer",
  },
});

export const CardImage = styled.div({
  position: "relative",
  marginLeft: "auto",
});

export const CardDetails = styled.div({
  width: "100%",
  padding: "20px 25px 15px 15px",
});

export const Section = styled.div({
  boxShadow: "1px 2px 15px #e5e5e5",
  backgroundColor: colors.light,
  padding: "1rem",
  margin: "1.5rem auto",
});

export const CardTheme = {
  FeedTitle,
  Creatorlink,
  Cardbox,
  CardImage,
  CardDetails,
  FeedLink,
};
