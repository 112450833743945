import { format } from "date-fns";
import { arSA } from "date-fns/locale";

const breakpoints = [576, 768, 992, 1280, 1400];
const mq = breakpoints.map(bp => `@media (max-width: ${bp}px)`);

const formatDate = rowDate =>
  format(new Date(rowDate), "dd LLLL y", { locale: arSA });

const slugify = str =>
  str
    .replace(/[^a-z0-9\u0621-\u064A]+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "") || "untitled";
const slugifyPath = (id, title) => `/blog/${id}-${slugify(title)}`;

export { mq, formatDate, slugifyPath };
