import Typography from "typography";

const typography = new Typography({
  baseFontSize: "22px",
  baseLineHeight: 1.45,
  scaleRatio: 3,
  headerFontFamily: ["Amiri", "Segoe UI", "Helvetica", "Arial", "sans-serif"],
  bodyFontFamily: ["Scheherazade", "serif"],
  bodyColor: "hsla(0,0%,0%,0.9)",
  headerWeight: 900,
  bodyWeight: 400,
  boldWeight: 700,
  overrideStyles: ({ adjustFontSizeTo, scale, rhythm }, options) => ({
    h1: {
      fontFamily: ["Reem Kufi", "tahoma"].join(",")
    },
    h3: {
      fontSize: "1.2rem"
    },
    ul: {
      marginRight: "1.5rem"
    },
    li: {
      marginBottom: "calc(1rem / 2)"
    }
  })
});

// Hot reload typography in development.
if (process.env.NODE_ENV !== `production`) {
  typography.injectStyles();
}

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
