import React from "react";
import { Link } from "gatsby";
import { colors, GreenLink } from "../style/theme";

function WriteWithUs({ withLine = true }) {
  return (
    <div>
      {withLine && (
        <hr
          css={{
            backgroundColor: colors.yellow,
            opacity: 0.5,
            width: "70%",
            height: "1px"
          }}
        />
      )}
      <p>
        مدوّنة الفهرست هي جهد مجتمعي تطوعي نسعى من خلاله إلى تعزيز ثقافة التدوين
        في العالم العربي.{" "}
        <GreenLink as={Link} to="/about/write-with-us">
          اكتب معنا
        </GreenLink>
      </p>
    </div>
  );
}

export default WriteWithUs;
