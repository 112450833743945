import React from "react";
import { GreenLink } from "../style/theme";

function Author({ name, url }) {
  return (
    <div
      css={{
        display: "flex",
        justifyContent: "center"
      }}
    >
      <p>
        بقلم: <GreenLink href={url}>{name}</GreenLink>
      </p>
    </div>
  );
}

export default Author;
